<template>
  <div class="rotation">
    <div class="content">
      <el-row>
        <el-col>
          <el-button type="primary" @click="updateVisible = true;dialogTitle = '新增';source = 'rotationAdd'">新增轮播</el-button>
        </el-col>
      </el-row>
      <div class="tableBox">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          max-height="650"
          border
          style="width: 100%">
          <el-table-column
            prop="createAt"
            label="创建时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="image"
            label="图片链接">
          </el-table-column>
          <el-table-column
            prop="status"
            label="预览图"
            width="200">
            <template slot-scope="scope">
                <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                  <img :src="scope.row.image" style="width: 100%; height: 100%" />
                  <div class="maskBox">
                    <i
                      class="el-icon-zoom-in"
                      style="cursor: pointer;"
                      @click="imgVisible = true; dialogImageUrl = scope.row.image"></i>
                  </div>
                </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="100">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.status === 1 ? 'primary' : 'danger'"
                disable-transitions>{{scope.row.status === 1 ? '正常' : '删除'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editRow(scope.$index, tableData)"
                  type="primary">
                  编辑
                </el-button>
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableData)"
                  type="primary">
                  删除
                </el-button>
              </template>
          </el-table-column>
        </el-table>
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            background
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="imgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="updateVisible"
      :before-close="beforeClose"
      width="30%">
      <span>请上传图片</span>
      <OssUpload
        v-if="updateVisible"
        :source="source"
        :file-list="fileList"
        @uploadSuccess="uploadSuccess"
        @uploadRemove="uploadRemove"
        ></OssUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="beforeClose()">取 消</el-button>
        <el-button type="primary" @click="handleConfirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="删除"
      :visible.sync="delVisible"
      width="30%">
      <span>是否删除图片?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false; itemId = -1">取 消</el-button>
        <el-button type="primary" @click="delVisible = false; bannerDel(itemId)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { bannerList, bannerDel, bannerUpdate } from '../../api/rotation';
import OssUpload from '../../components/OssUpload.vue';

export default {
  components: {
    OssUpload
  },
  data() {
      return {
        dialogTitle: '',
        source: '',
        imageUrl: '',
        fileList: [],
        itemId: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        tableData: [],
        dialogImageUrl: '',
        imgVisible: false,
        updateVisible: false,
        delVisible: false,
        tableLoading: true,
      }
    },
    mounted() {
      /**
       * @author XU
       */
      this.bannerList();
    },
    methods: {
      async bannerList() {
        try {
          const params = {
            page: this.currentPage,
            limit: this.pageSize
          };
          this.tableLoading = true;
          let data = await bannerList(params);
          if (data.zbanerList) {
            this.total = data.total;
            this.tableData = data.zbanerList;
          }
        } catch (error) {
          console.log("error:", error)
        }
        this.tableLoading = false;
      },
      async bannerDel(id) {
        try {
          const params = {
            id
          };
          await bannerDel(params);
          this.bannerList();
        } catch (error) {
          console.log("error:", error)
        }
      },
      async bannerUpdate(id) {
        try {
          const params = {
            image: this.imageUrl
          };
          if (id != -1 && this.source === 'rotationEdit') {
            params.id = id;
          }
          await bannerUpdate(params);
          this.bannerList();
        } catch (error) {
          console.log("error:", error)
        }
      },
      editRow(index, list) {
        this.dialogTitle = "编辑";
        this.source = "rotationEdit";
        this.updateVisible = true;
        this.fileList = [{url: list[index].image}];
        this.itemId = list[index].id;
      },
      deleteRow(index, list) {
        this.delVisible = true;
        this.itemId = list[index].id;
      },
      handleConfirm() {
        if (this.imageUrl === "") {
          this.$message({
            message: '图片地址为空, 请重新上传图片',
            type: 'warning'
          });
          return;
        }
        this.updateVisible = false;
        this.bannerUpdate();
      },
      handleSizeChange(size) {
        this.pageSize = size;
      },
      handleCurrentChange(current) {
        this.currentPage = current;
        this.bannerList();
      },
      uploadSuccess(_, url) {
        this.imageUrl = url;
      },
      uploadRemove() {
        this.imageUrl = "";
      },
      beforeClose() {
        this.imageUrl = "";
        this.fileList = [];
        this.updateVisible = false;
      }
    }
}
</script>

<style lang="scss" scoped>
.rotation {
  .content {
    .tableBox {
      padding-top: 20px;
    }
    .paginationBox {
      padding-top: 20px;
      text-align: center;
    }
  }
  .imageBox {
    position: relative;
    .maskBox {
      display: none;
      position: absolute;
      width: 150px;
      height: 150px;
      padding-top: 55px;
      left: 0;
      top: 0;
      text-align: center;
      color: #fff;
      font-size: 20px;
      background-color: rgba(0,0,0,.5);
      transition: opacity .3s;
    }
    &:hover {
      .maskBox {
        display: block;
      }
    }
  }
}
</style>