<template>
    <div style="padding: 10px 0">
        <el-upload
            list-type="picture-card"
            :class="{hide:hideUpload}"
            action=""
            :file-list="fileList"
            :http-request="uploadFile"
            :before-upload="beforeUpload"
            :on-remove="uploadRemove">
            <i class="el-icon-plus"></i>
        </el-upload>
    </div>
</template>

<script>
import moment from 'moment';
import data from '../config/ossUpload.json';

const OSS = require('ali-oss');

const client = new OSS({
    endpoint: data.endpoint,
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.accessKeySecret,
    bucket: data.bucket,
    region: data.region,
});

export default {
    props: {
        source: {
            type: String,
            default: ""
        },
        fileList: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            hideUpload: false
        }
    },
    mounted() {
        if (this.fileList.length > 0) {
            this.hideUpload = true;
        }
    },
    methods: {
        beforeUpload(file) {
            const isType = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt4M = file.size / 1024 / 1024 < 4;
            if (!isType) {
                this.$message.error('上传图片格式为 jpg 或 png ');
            }
            if (!isLt4M) {
                this.$message.error('上传图片大小不能超过 4MB!');
            }
            return isType && isLt4M;
        },
        async uploadFile (options) {
            try {
                let file = options.file;
                const fileType = file.name.substr(file.name.lastIndexOf('.'));
                const date = moment().format("YYYYMMDDHHmmss");
                const uuid = this.random6();
                let fileNames = `${date}${uuid}${fileType}`;
                client.put('erp/banner/' + fileNames, file).then(res=>{
                    if (res.res.statusCode === 200) {
                        this.$message({
                            message: '图片上传成功',
                            type: 'success'
                        });
                        this.uploadSuccess(res.url);
                        this.hideUpload = true;
                    }else {
                        this.$message({
                            message: '图片上传失败',
                            type: 'error'
                        });
                    }
                })
            }catch (e) {
                this.$message({
                    message: '图片上传失败',
                    type: 'error'
                });
            }

        },
        random6() {
            var code_fill_str = ["000000", "00000", "0000", "000", "00", "0", ""];
            var code = '' + parseInt(Math.random()*1000000);
            code = code_fill_str[code.length] + code; 
            return code;
        },
        uploadSuccess(url) {
            this.$emit("uploadSuccess", this.source, url);
        },
        uploadRemove() {
            this.hideUpload = false;
            this.$emit("uploadRemove");
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.hide .el-upload--picture-card {
    display: none;
}
</style>