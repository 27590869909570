import http from "../utils/http";
import { stringify } from 'qs';

export function bannerList(params) {
    return http.get("/zBanner/bannerList?" + stringify(params));
}

export function bannerDel(params) {
    return http.get("/zBanner/update?" + stringify(params));
}

export function bannerUpdate(params) {
    return http.post("/zBanner/add?" + stringify(params));
}
